import React, { Component } from 'react';
import './SelectField.scss';

import { ReactComponent as DropdownChevronIcon } from './assets/dropdown-chevron.svg';

class SelectField extends Component {
  /** @var boolean */
  static formField = true;

  /**
   * constructor
   *
   * This is the main constructor
   * for the class.
   *
   * @type function
   * @since 0.0.1
   */
  constructor(props) {
    super(props);

    // set the field name
    this.fieldName = this.props.minmax
      ? `${this.props.name}-${this.props.minmax}`
      : this.props.name;

    // set the unique id
    const { uniqueId } = this.props;
    this.uniqueId = `${uniqueId}__field-${this.fieldName}`

    // set the state

    // copy the options property without
    // referencing the same object in the
    // parent component
    //
    // this prevents changes on this object
    // from affecting the parent variable
    const options = [];
    for (let option of this.props.options) {
      options.push(Object.assign({}, option));
    }

    this.state = {
      options: options.map(option => {
        option.checked = option.checked === true;
        return option;
      }),
      dropdownOpen: false,
      allOptionsAreSelected: false,
    }
  }

  /**
   * componentDidMount
   *
   * Fires when the component
   * finishes mounting.
   *
   * @type hook
   * @since 0.0.1
   */
  componentDidMount() {
    // set the initial value
    // to the form state
    this.props.updateValue(prevFormData => {
      prevFormData[this.fieldName] =
        this.props.multiple ? [] : "";
      return { ...prevFormData };
    });
  }

  /**
   * componentWillUnmount
   *
   * Fires when the component
   * is about to unmount.
   *
   * @type function
   * @since 0.0.1
   *
   * @param NA
   * @return MA
   */
  componentWillUnmount() {
    document.removeEventListener('click', this.closeDropdown);
  }

  /**
   * getLabel
   *
   * Renders the field label,
   * if one is specified.
   *
   * @type function
   * @since 0.0.1
   *
   * @param NA
   * @return JSX
   */
  getLabel = () => {
    if (!this.props.label) return null;
    return (
      <label htmlFor={this.uniqueId}>
        {this.props.label}
      </label>
    );
  }

  /**
   * getPlaceholderText
   *
   * Retrieves the placeholder text
   * based on the current value.
   *
   * @type function
   * @since 0.0.1
   *
   * @param NA
   * @return string
   */
  getPlaceholderText = () => {
    const placeholder = this.state.options
      .filter(o => o.checked)
      .map(o => o.label)
      .join(', ');
    return placeholder || this.props.placeholder;
  }

  /**
   * openDropdown
   *
   * Opens the dropdown menu.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object event
   * @return NA
   */
  showDropdown = event => {
    event.preventDefault();
    this.setState({ dropdownOpen: true }, () => {
      document.addEventListener('click', this.closeDropdown);
    });
  }

  /**
   * closeDropdown
   *
   * Closes the dropdown menu, if called
   * directly or the event target is not
   * the actual dropdown menu.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object event
   * @return NA
   */
  closeDropdown = event => {
    if (!event || !this.dropdownMenu.contains(event.target)) {
      this.setState({ dropdownOpen: false }, () => {
        document.removeEventListener('click', this.closeDropdown);
      });
    }
  }

  /**
   * updateFormData
   *
   * Updates the form data with the
   * selected options.
   *
   * @type function
   * @since 0.0.1
   *
   * @param NA
   * @return NA
   */
  updateFormData = () => {
    this.props.updateValue(prevFormData => {
      let checkedOptions = this.state.options
        .filter(o => o.checked);

      let value = this.props.multiple
        ? checkedOptions.map(o => o.value)
        : checkedOptions[0].value;

      prevFormData[this.fieldName] = value;
      return { ...prevFormData };
    });
  }

  /**
   * handleOnChange
   *
   * Toggles the 'checked' state
   * for the specified option.
   *
   * @type function
   * @since 0.0.1
   *
   * @param number optionKey
   * @return NA
   */
  handleOnChange = async optionKey => {
    // close the dropdown menu,
    // if multiple selection is disabled
    if (!this.props.multiple) this.closeDropdown();

    // update the local state
    await this.setState(prevState => {
      let { options } = prevState;
      if (this.props.multiple) {
        options[optionKey].checked =
          !options[optionKey].checked;

        // check if all options are checked
        prevState.allOptionsAreSelected = options
          .filter(o => !o.checked)
          .length === 0;
      } else {
        options = options.map((o, x) => {
          o.checked = x === optionKey;
          return o;
        });
      }

      return { ...prevState, options };
    });

    // update the form data
    this.updateFormData();
  }

  /**
   * handleSelectAll
   *
   * Handles the 'Select All' event.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object event
   * @return NA
   */
  handleSelectAll = async event => {
    const {
      target: { checked }
    } = event;

    await this.setState(prevState => {
      let { options } = prevState;
      options = options.map(o => ({ ...o, checked }))
      return {
        ...prevState,
        options,
        allOptionsAreSelected: checked,
      }
    });

    // update the form data
    this.updateFormData();
  }

  // render
  render() {
    return (
      <div className="form__field type-custom-select">
        {this.getLabel()}
        <button
          type="button"
          className="toggler"
          onClick={this.showDropdown}>
          <span>
            {this.getPlaceholderText()}
          </span>
          <DropdownChevronIcon />
        </button>

        <div
          className={`options ${this.state.dropdownOpen ? 'open' : ''}`.trim()}
          ref={element => this.dropdownMenu = element}>
          {this.props.multiple &&
            <div className="option option__select-all">
              <input
                type="checkbox"
                name={this.fieldName}
                id={`${this.uniqueId}-all`}
                checked={this.state.allOptionsAreSelected}
                onChange={this.handleSelectAll}
                className="option__field"
              />

              <label
                htmlFor={`${this.uniqueId}-all`}
                tabIndex="0"
                className="option__label">
                Selecionar tudo
              </label>
            </div>
          }

          {this.state.options.map((option, optionKey) => (
            <div
              key={optionKey}
              className="option">
              <input
                type={this.props.multiple ? 'checkbox' : 'radio'}
                name={this.fieldName}
                id={`${this.uniqueId}-${optionKey}`}
                checked={option.checked}
                onChange={() => this.handleOnChange(optionKey)}
                className="option__field"
              />
              <label
                htmlFor={`${this.uniqueId}-${optionKey}`}
                tabIndex="0"
                className="option__label">
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default SelectField;
