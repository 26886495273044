import React from 'react';
import './Gallery.scss';

import TextImage from '../../assets/img/gallery/text-image.png';
import TextImageAVIF from '../../assets/img/gallery/text-image.avif';
import TextImageWEBP from '../../assets/img/gallery/text-image.webp';

import galleryImages from './images.js';

const Gallery = () => {

  // render
  return (
    <section className="section-gallery">
      <div className="container">
        <div className="title">
          <div className="top">
            Viva em sintonia com sua
          </div>
          <picture>
            <source srcset={TextImageAVIF} type="image/avif" />  
            <source srcset={TextImageWEBP} type="image/webp" />  
            <img src={TextImage} alt="Viva em sintonia com sua essência" className="el-img"/>
          </picture>
        </div>

        <div className="images">
          {galleryImages.map((col, colKey) => (
            <div
              key={colKey}
              className="col">
              {col.map((item, itemKey) => (
                <div
                  key={itemKey}
                  className="item"
                >
                  <div
                    className={`caption ${item.captionPosition}`}>
                    {item.caption}
                  </div>

                  <div
                    className="image"
                    rel="lazy_load"
                    data-url={item.src}
                    data-webp={item.webp ? item.webp : 'false'}
                    data-avif={item.avif ? item.avif : 'false'}
                    data-distance="0.01"
                  ></div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Gallery;
