import React from 'react';
import './Features.scss';

import BackgroundImage from '../../assets/img/features/background.jpg';
import BackgroundImageAVIF from '../../assets/img/features/background.avif';
import BackgroundImageWEBP from '../../assets/img/features/background.webp';
import Feature1Icon from '../../assets/img/features/feature-1-icon.png';
import Feature2Icon from '../../assets/img/features/feature-2-icon.png';
import Feature3Icon from '../../assets/img/features/feature-3-icon.png';
import Feature4Icon from '../../assets/img/features/feature-4-icon.png';
import Feature5Icon from '../../assets/img/features/feature-5-icon.png';
import Feature6Icon from '../../assets/img/features/feature-6-icon.png';
import Feature7Icon from '../../assets/img/features/feature-7-icon.png';
import Feature8Icon from '../../assets/img/features/feature-8-icon.png';
import Feature9Icon from '../../assets/img/features/feature-9-icon.png';
import Feature10Icon from '../../assets/img/features/feature-10-icon.png';
import Feature11Icon from '../../assets/img/features/feature-11-icon.png';
import Feature12Icon from '../../assets/img/features/feature-12-icon.png';
import Feature13Icon from '../../assets/img/features/feature-13-icon.png';
import Feature14Icon from '../../assets/img/features/feature-14-icon.png';
import Feature15Icon from '../../assets/img/features/feature-15-icon.png';
import Feature16Icon from '../../assets/img/features/feature-16-icon.png';

const Features = () => {
  /** @var object */
  const featuresList = [
    {
      text: 'Garagem<br /> de barcos',
      icon: Feature1Icon,
    },
    {
      text: 'Marina',
      icon: Feature2Icon,
    },
    {
      text: 'Manutenção de<br /> embarcações',
      icon: Feature3Icon,
    },
    {
      text: 'Loja de<br /> conveniência',
      icon: Feature4Icon,
    },
    {
      text: 'Restaurante',
      icon: Feature5Icon,
    },
    {
      text: 'Lounge bar',
      icon: Feature6Icon,
    },
    {
      text: 'Esplanada',
      icon: Feature7Icon,
    },
    {
      text: 'Mirante',
      icon: Feature8Icon,
    },
    {
      text: 'Pista de cooper<br /> com Wi-Fi',
      icon: Feature9Icon,
    },
    {
      text: 'Heliponto',
      icon: Feature10Icon,
    },
    {
      text: 'Quadras de tênis<br /> de saibro e rápida',
      icon: Feature11Icon,
    },
    {
      text: 'Quadra<br /> poliesportiva',
      icon: Feature12Icon,
    },
    {
      text: 'Academia',
      icon: Feature13Icon,
    },
    {
      text: 'Pet place',
      icon: Feature14Icon,
    },
    {
      text: 'Espaço kids',
      icon: Feature15Icon,
    },
    {
      text: 'Pit fire',
      icon: Feature16Icon,
    },
  ];

  // render
  return (
    <section className="section-features">
      <div
        className="background"
        rel="lazy_load"
        data-url={BackgroundImage}
        data-webp={BackgroundImageWEBP}
        data-avif={BackgroundImageAVIF}
        data-distance="0.01"
      ></div>

      <div className="container">
        <div className="title">
          Acesso exclusivo ao que<br />
          há de mais incrível
        </div>

        <div className="features">
          {featuresList.map((feature, key) => (
            <div
              key={key}
              className="feature">
              <div
                className="icon"
                rel="lazy_load"
                data-url={feature.icon}
                data-webp="false"
                data-avif="false"
                data-distance="0.01"
              ></div>

              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: feature.text }}>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Features;
