import React from 'react';
import './Hero.scss';

import { isMobile } from 'react-device-detect';

import Integrations from '../../providers/Integrations';

import Form, { Field } from '../../components/Form/Form';

import BackgroundImage from '../../assets/img/hero/background.jpg';
import BackgroundImageAVIF from '../../assets/img/hero/background.avif';
import LogoImage from '../../assets/img/hero/logo-header.png';
import TextImage from '../../assets/img/hero/text-viva.png';
import Feature1Icon from '../../assets/img/hero/feature-1-icon.png';
import Feature2Icon from '../../assets/img/hero/feature-2-icon.png';
import Feature3Icon from '../../assets/img/hero/feature-3-icon.png';
import Feature4Icon from '../../assets/img/hero/feature-4-icon.png';
import MouseIcon from '../../assets/img/hero/mouse-icon.png';

const Hero = () => {
  /** @var object */
  const featuresList = [
    {
      text: 'Lotes de<br/>até 1.000 m²',
      icon: Feature1Icon,
    },
    {
      text: 'Condomínio fechado<br /> de altíssimo luxo',
      icon: Feature2Icon,
    },
    {
      text: 'Lazer premium<br /> completo',
      icon: Feature3Icon,
    },
    {
      text: 'Próximo a todos<br /> os centros urbanos',
      icon: Feature4Icon,
    },
  ];

  // render
  return (
    <section className="section-hero">
      <div className="background">
        <picture>
          <source srcset={BackgroundImageAVIF} type="image/avif" />  
          <img src={BackgroundImage} alt="Mirrage" className="el-img el-img__cover"/>
        </picture>
      </div>

      <div className="container">
        <div className="logo">
          <img
            src={LogoImage}
            alt="Mirrage Nautic Club"
            className="el-img"
          />
        </div>

        <div className="content">
          <div className="left">
            <div className="title">
              <span className="text">Para cada momento,</span>
              <img
                src={TextImage}
                alt="Viva"
                className="el-img text-image"
              />
            </div>

            <div className="headline">
              <p>
                No Mirrage Nautic Club, o destino é tão importante quanto o trajeto. E quem assume
                o leme desta jornada única é você.
              </p>
            </div>

            <div className="features">
              {featuresList.map((feature, key) => (
                <div
                  key={key}
                  className="feature">
                  <div className="icon">
                    <img
                      src={feature.icon}
                      alt={feature.text}
                      className="el-img"
                    />
                  </div>
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: feature.text }}>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {!isMobile &&
            <div className="right">
              <div className="call-to-action">
                <div className="headline">
                  Baixe a apresentação completa<br />
                  do empreendimento
                </div>

                <div className="form-wrapper">
                  <Form
                    noStyle
                    uniqueId="topForm"
                    onSubmit={Integrations.sendToLeadPower}>
                    <div className="form__fields">
                      <Field
                        name="name"
                        placeholder="Digite o seu nome"
                        label="Nome"
                        required
                      />
                      <Field
                        type="email"
                        name="email"
                        placeholder="Digite o seu e-mail"
                        label="E-mail"
                        required
                      />
                      <Field
                        type="phone"
                        name="phone"
                        placeholder="(00) 00000-0000"
                        label="Telefone"
                        required
                      />
                    </div>

                    <div className="form__submit">
                      <button
                        type="submit"
                        className="btn">
                        <span>Baixar apresentação</span>
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          }
        </div>

        <div className="scroll-indicator">
          <img
            src={MouseIcon}
            alt="Scroll Down"
            className="el-img icon"
          />
          <span className="text">
            Scroll down
          </span>
        </div>
      </div>
    </section>
  );
}

export default Hero;
