import React from 'react';
import './Location.scss';

import BackgroundImage from '../../assets/img/location/background.png';
import BackgroundImageAVIF from '../../assets/img/location/background.avif';
import BackgroundImageWEBP from '../../assets/img/location/background.webp';
import TextImage from '../../assets/img/location/text-image.png';
import TextImageAVIF from '../../assets/img/location/text-image.avif';
import TextImageWEBP from '../../assets/img/location/text-image.webp';
import MapImage from '../../assets/img/location/map.jpg';
import MapImageAVIF from '../../assets/img/location/map.avif';

const Location = () => {

  // render
  return (
    <section className="section-location">
      <div
        className="background"
        rel="lazy_load"
        data-url={BackgroundImage}
        data-webp={BackgroundImageWEBP}
        data-avif={BackgroundImageAVIF}
        data-distance="0.01"
      ></div>

      <div className="container">
        <div className="top">
          <div className="content">
            <div className="title">
              <picture>
                <source srcset={TextImageAVIF} type="image/avif" />  
                <source srcset={TextImageWEBP} type="image/webp" />  
                <img src={TextImage} alt="O paraíso é logo ali" className="el-img"/>
              </picture>
              <div className="bottom">
                É logo ali
              </div>
            </div>

            <div className="text">
              <p>
                A apenas 15 km de Divinópolis e 118 km de Belo Horizonte,
                o Mirrage Nautic Club fica próximo de todos os grandes centros urbanos.
              </p>
            </div>

            <div className="cta">
              <a
                href="https://www.google.com.br/maps/dir//MIRRAGE+NAUTIC+CLUB+-+Carmo+do+Cajuru,+MG,+35510-000/@-20.2936522,-44.7268676,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0xa0bfd999daa681:0xae47392359f6cf92!2m2!1d-44.7246789!2d-20.2936522?hl=en-br"
                title="Ver no mapa"
                aria-label="Ver no mapa"
                class="btn"
                target="_blank"
                rel="noopener noreferrer"
               >
                 <span>Veja como chegar</span>
               </a>
            </div>
          </div>
        </div>

        <div
          className="map"
          rel="lazy_load"
          data-url={MapImage}
          data-webp="false"
          data-avif={MapImageAVIF}
          data-distance="0.01"
        ></div>
      </div>
    </section>
  );
}

export default Location;
