import Item1Image from '../../assets/img/gallery/image-1.jpg';
import Item1ImageAVIF from '../../assets/img/gallery/image-1.avif';
import Item2Image from '../../assets/img/gallery/image-2.jpg';
import Item2ImageAVIF from '../../assets/img/gallery/image-2.avif';
import Item2ImageWEBP from '../../assets/img/gallery/image-2.webp';
import Item3Image from '../../assets/img/gallery/image-3.jpg';
import Item3ImageAVIF from '../../assets/img/gallery/image-3.avif';
import Item3ImageWEBP from '../../assets/img/gallery/image-3.webp';
import Item4Image from '../../assets/img/gallery/image-4.jpg';
import Item4ImageAVIF from '../../assets/img/gallery/image-4.avif';
import Item4ImageWEBP from '../../assets/img/gallery/image-4.webp';
import Item5Image from '../../assets/img/gallery/image-5.jpg';
import Item5ImageAVIF from '../../assets/img/gallery/image-5.avif';
import Item6Image from '../../assets/img/gallery/image-6.jpg';
import Item6ImageAVIF from '../../assets/img/gallery/image-6.avif';
import Item6ImageWEBP from '../../assets/img/gallery/image-6.webp';
import Item7Image from '../../assets/img/gallery/image-7.jpg';
import Item7ImageAVIF from '../../assets/img/gallery/image-7.avif';
import Item7ImageWEBP from '../../assets/img/gallery/image-7.webp';
import Item8Image from '../../assets/img/gallery/image-8.jpg';
import Item8ImageAVIF from '../../assets/img/gallery/image-8.avif';
import Item9Image from '../../assets/img/gallery/image-9.jpg';
import Item9ImageAVIF from '../../assets/img/gallery/image-9.avif';
import Item10Image from '../../assets/img/gallery/image-10.jpg';
import Item10ImageAVIF from '../../assets/img/gallery/image-10.avif';
import Item10ImageWEBP from '../../assets/img/gallery/image-10.webp';
import Item11Image from '../../assets/img/gallery/image-11.jpg';
import Item11ImageAVIF from '../../assets/img/gallery/image-11.avif';
import Item11ImageWEBP from '../../assets/img/gallery/image-11.webp';
import Item12Image from '../../assets/img/gallery/image-12.jpg';
import Item12ImageAVIF from '../../assets/img/gallery/image-12.avif';
import Item12ImageWEBP from '../../assets/img/gallery/image-12.webp';

const galleryImages = [
  [
    {
      src: Item1Image,
      avif: Item1ImageAVIF,
      webp: false,
      caption: 'Portaria',
      captionPosition: 'bottom-left',
    },
    {
      src: Item2Image,
      avif: Item2ImageAVIF,
      webp: Item2ImageWEBP,
      caption: 'Praça da fonte',
      captionPosition: 'bottom-left',
    },
    {
      src: Item3Image,
      avif: Item3ImageAVIF,
      webp: Item3ImageWEBP,
      caption: 'Heliponto',
      captionPosition: 'top-right',
    },
    {
      src: Item4Image,
      avif: Item4ImageAVIF,
      webp: Item4ImageWEBP,
      caption: 'Loja de conveniência',
      captionPosition: 'top-left',
    },
    {
      src: Item5Image,
      avif: Item5ImageAVIF,
      webp: false,
      caption: 'Pet place',
      captionPosition: 'bottom-right',
    },
    {
      src: Item6Image,
      avif: Item6ImageAVIF,
      webp: Item6ImageWEBP,
      caption: 'Pista de cooper com Wi-Fi',
      captionPosition: 'bottom-left',
    },
  ],
  [
    {
      src: Item7Image,
      avif: Item7ImageAVIF,
      webp: Item7ImageWEBP,
      caption: 'Píer',
      captionPosition: 'bottom-left',
    },
    {
      src: Item8Image,
      avif: Item8ImageAVIF,
      webp: false,
      caption: 'Garagem de barcos',
      captionPosition: 'top-right',
    },
    {
      src: Item9Image,
      avif: Item9ImageAVIF,
      webp: false,
      caption: 'Lounge bar',
      captionPosition: 'bottom-right',
    },
    {
      src: Item10Image,
      avif: Item10ImageAVIF,
      webp: Item10ImageWEBP,
      caption: 'Pit fire',
      captionPosition: 'bottom-left',
    },
    {
      src: Item11Image,
      avif: Item11ImageAVIF,
      webp: Item11ImageWEBP,
      caption: 'Espaço kids',
      captionPosition: 'top-right',
    },
    {
      src: Item12Image,
      avif: Item12ImageAVIF,
      webp: Item12ImageWEBP,
      caption: 'Academia',
      captionPosition: 'top-left',
    },
  ],
];

export default galleryImages;
