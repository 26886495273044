import React from 'react';
import './Footer.scss';

import LogoImage from '../../assets/img/footer/logo.png';
import PhoneIcon from '../../assets/img/footer/phone-icon.png';
import LotusLogoImage from '../../assets/img/footer/lotus-logo.png';
import TottenLogoImage from '../../assets/img/footer/totten-logo.png';
import MosaicoLogoImage from '../../assets/img/footer/mosaico-logo.png';
import TrzLogoImage from '../../assets/img/footer/300-logo.png';
import { ReactComponent as WppIcon } from '../../assets/icons/whatsapp.svg';

const Footer = () => {

  // render
  return (
    <footer className="section-footer">
      <div className="container">
        <div className="left">
          <div className="logo">
            <img
              src={LogoImage}
              alt="Mirrage Nautic Club"
              className="el-img"
            />
          </div>

          <div className="contact-numbers">
            <div className="headline">
              <div className="icon">
                <img
                  src={PhoneIcon}
                  alt="Informações e reservas"
                  className="el-img"
                />
              </div>
              <span>Informações e Reservas</span>
            </div>
            <div className="numbers">
              <a
                href="tel:3136437063"
                className="link"
                title="Ligue agora!">
                <span>31 3643-7063</span>
              </a>

              <a
                href="https://api.whatsapp.com/send?phone=553198741208"
                title="Fale por whatsapp"
                aria-label="Fale por whatsapp"
                target="_blank"
                rel="noopener noreferrer"
               >
                <span>31 99874-1208</span>
                <WppIcon />
              </a>
            </div>
          </div>

          <div className="copyright">
            &copy; 2020 - Mirrage Nautic Club - Todos os direitos reservados - All rights reserved
          </div>
        </div>

        <div className="right">
          <div className="top">
            <div className="item">
              <div className="item__title">
                Realização e Incorporação:
              </div>

              <div className="item__logo">
                <a
                  href="https://google.com"
                  title="Visite o site!"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="item__link">
                  <img
                    src={LotusLogoImage}
                    alt="Lotus Empreendimentos"
                    className="el-img"
                  />
                </a>
              </div>
            </div>

            <div className="item">
              <div className="item__title">
                Construção
              </div>
              <div className="item__logo">
                <a
                  href="https://google.com"
                  title="Visite o site!"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="item__link">
                  <img
                    src={TottenLogoImage}
                    alt="Totten Construtora"
                    className="el-img"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="bottom">
            <div className="headline">
              Parceiros:
            </div>

            <div className="items">
              <div className="item">
                <div className="item__title">
                  Arquitetura:
                </div>
                <div className="item__logo">
                  <a
                    href="https://google.com"
                    title="Visite o site!"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="item__link">
                    <img
                      src={MosaicoLogoImage}
                      alt="Mosaico Arquitetura"
                      className="el-img"
                    />
                  </a>
                </div>
              </div>

              <div className="item">
                <div className="item__title">
                  Marketing:
                </div>
                <div className="item__logo">
                  <a
                    href="https://trezentos.com.br"
                    title="300 Comunicação"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="item__link">
                    <img
                      src={TrzLogoImage}
                      alt="300 Comunicação"
                      className="el-img"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
