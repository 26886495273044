import React, { useRef, useState } from 'react';
import './MobileCta.scss';

import Integrations from '../../providers/Integrations';

import Form, { Field } from '../../components/Form/Form';

import { ReactComponent as CloseIcon } from '../../assets/icons/mobile-close.svg';

const MobileCta = () => {
  /** @ref The form */
  const theForm = useRef();

  /** @state boolean */
  const [isOpen, setOpenState] = useState(false);

  // render
  return (
    <>
      <button
        type="button"
        className="mobile-cta-toggler btn"
        onClick={() => setOpenState(true)}>
        Baixar apresentação
      </button>

      <div className={`mobile-cta ${isOpen ? 'open' : ''}`.trim()}>
        <div
          className="background"
          onClick={() => setOpenState(false)}>
        </div>

        <div className="flex-wrapper">
          <div className="scroll">
            <div className="top">
              <button
                type="button"
                title="Fechar"
                aria-label="Fechar"
                className="close"
                onClick={() => setOpenState(false)}>
                <CloseIcon />
              </button>

              <div className="headline">
                Baixe a apresentação completa<br /> do empreendimento
              </div>

              <Form
                ref={theForm}
                noStyle
                uniqueId="topForm"
                onSubmit={Integrations.sendToLeadPower}>
                <div className="form__fields">
                  <Field
                    name="name"
                    placeholder="Digite o seu nome"
                    label="Nome"
                    required
                  />
                  <Field
                    type="email"
                    name="email"
                    placeholder="Digite o seu e-mail"
                    label="E-mail"
                    required
                  />
                  <Field
                    type="phone"
                    name="phone"
                    placeholder="(00) 00000-0000"
                    label="Telefone"
                    required
                  />
                  <button
                    type="submit"
                    title="Baixar apresentação"
                    aria-label="Baixar apresentação"
                    className="hidden-submit-button">
                  </button>
                </div>
              </Form>
            </div>

            <div className="bottom">
              <button
                type="button"
                className="btn"
                onClick={() => theForm.current.submit()}>
                Baixar apresentação
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileCta;
