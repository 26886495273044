import React from 'react';
import './Intro.scss';

import FeaturedImage from '../../assets/img/intro/featured-image.jpg';
import FeaturedImageAVIF from '../../assets/img/intro/featured-image.avif';
import FeaturedImageWEBP from '../../assets/img/intro/featured-image.webp';
import TextImage from '../../assets/img/intro/text-image.png';
import TextImageAVIF from '../../assets/img/intro/text-image.avif';
import TextImageWEBP from '../../assets/img/intro/text-image.webp';

const Intro = () => {

  // render
  return (
    <section className="section-intro">
      <div className="container">
        <div
          className="featured-image"
          rel="lazy_load"
          data-url={FeaturedImage}
          data-webp={FeaturedImageWEBP}
          data-avif={FeaturedImageAVIF}
          data-distance="0.01"
        ></div>

        <div className="content">
          <div className="title">
            <div className="top">
              Seu<br /> Lifestyle<br /> Náutico<br />
            </div>
            <picture>
              <source srcset={TextImageAVIF} type="image/avif" />  
              <source srcset={TextImageWEBP} type="image/webp" />  
              <img src={TextImage} alt="Mirrage" className="el-img"/>
            </picture>
            <div className="bottom">
              Aqui
            </div>
          </div>

          <div className="text">
            <p>Um verdadeiro recanto intocado de natureza, beleza e lazer que a Lotus Empreendimentos traz com exclusividade para a vida da sua família. Seja para compartilhar a tranquilidade das águas, seja para viver a intensidade dos esportes náuticos, o Mirrage Nautic Club é perfeito para escapar da urbanidade sem perder a conveniência.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Intro;
