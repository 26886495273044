import React from 'react';
import './CallToAction.scss';

import { isMobile } from 'react-device-detect';

import Integrations from '../../providers/Integrations';

import Form, { Field } from '../../components/Form/Form';

import BackgroundImage from '../../assets/img/call-to-action/background.jpg';
import BackgroundImageAVIF from '../../assets/img/call-to-action/background.avif';
import BackgroundImageWEBP from '../../assets/img/call-to-action/background.webp';
import TextImage from '../../assets/img/call-to-action/text-image.png';
import TextImageAVIF from '../../assets/img/call-to-action/text-image.avif';
import TextImageWEBP from '../../assets/img/call-to-action/text-image.webp';

const CallToAction = () => {
  // render
  return (
    <section className="section-call-to-action">
      <div
        className="background"
        rel="lazy_load"
        data-url={BackgroundImage}
        data-webp={BackgroundImageWEBP}
        data-avif={BackgroundImageAVIF}
        data-distance="0.01"
      ></div>

      <div className="container">
        <div className="content-wrapper">
          <div className="content">
            <div className="title">
              <picture>
                <source srcset={TextImageAVIF} type="image/avif" />  
                <source srcset={TextImageWEBP} type="image/webp" />  
                <img src={TextImage} alt="Encontre-se em tudo aquilo que você procurava" className="el-img"/>
              </picture>
              <div className="bottom">
                Em tudo aquilo<br /> que você procurava
              </div>
            </div>

            <div className="text">
              <p>
                Quer mais detalhes? Baixe a apresentação
                e conheça o Mirrage Nautic Club por completo.
              </p>
            </div>
          </div>

          {!isMobile &&
            <div className="form-wrapper">
              <Form
                noStyle
                uniqueId="bottomForm"
                onSubmit={Integrations.sendToLeadPower}>
                <div className="form__fields">
                  <Field
                    name="name"
                    placeholder="Digite o seu nome"
                    label="Nome"
                    required
                  />
                  <Field
                    type="email"
                    name="email"
                    placeholder="Digite o seu e-mail"
                    label="E-mail"
                    required
                  />
                  <Field
                    type="phone"
                    name="phone"
                    placeholder="(00) 00000-0000"
                    label="Telefone"
                    required
                  />
                </div>

                <div className="form__submit">
                  <button
                    type="submit"
                    className="btn">
                    <span>Baixar apresentação</span>
                  </button>
                </div>
              </Form>
            </div>
          }
        </div>
      </div>
    </section>
  );
}

export default CallToAction;
