import React, {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import './Form.scss';

import Field from './Field';
import SelectField from './SelectField';

const Form = forwardRef((props, ref) => {
  const {
    noStyle = false,
    uniqueId,
    onSubmit,
  } = props;

  let { hideLoader } = props;

  /** @ref Form */
  const theForm = useRef();

  /** @state object */
  const [formFields, setFormFields] = useState({});

  /** @state object */
  const [invalidFields, setInvalidFields] = useState([]);

  /** @state object */
  const [children, setChildren] = useState([]);

  /** @state object */
  const [formData, updateFormData] = useState({});

  /** @state boolean */
  const [formLoaderIsOpen, setFormLoaderIsOpen] = useState(false);

  /** @state JSX */
  const [loaderMessage, setLoaderMessage] = useState(
    <Fragment>
      <p>Enviando mensagem...</p>
      <p>Por favor, aguarde.</p>
    </Fragment>
  );

  // prepare children
  useEffect(() => {
    const prepareChildren = childrenList => {
      return React.Children.toArray(childrenList).map(child => {
        if (typeof child === 'string') return child;

        // check if this child is a form field
        const isFormField = (
          typeof child.type === 'function' &&
          child.type.formField
        );

        // check if the field is invalid
        const fieldIsInvalid = isFormField && invalidFields
          .indexOf(child.props.name) !== -1;

        // add extra props
        const childProps = isFormField ? {
          uniqueId,
          formData,
          invalid: fieldIsInvalid,
          updateValue: updateFormData,
        } : {
          ...(child.props.children && {
            children: prepareChildren(child.props.children)
          }),
        }

        // save form field information
        if (isFormField) setFormFields(
          prevFormFields => ({
            ...prevFormFields,
            [child.props.name]: {
              type: child.props.type || false,
              required: child.props.required || false,
            }
          })
        );

        // return cloned child
        return React.cloneElement(child, childProps);
      });
    }

    setChildren(prepareChildren(props.children));
  }, [props.children, invalidFields, formData, uniqueId]);

  /**
   * validateFormData
   *
   * Validates the form data.
   *
   * @type function
   * @since 0.0.1
   *
   * @param NA
   * @return boolean
   */
  const validateFormData = () => {
    const invalidFields = [];

    for (let name in formFields) {
      const { type, required } =
        formFields[name];
      const value = formData[name].replace(/_/g, '');

      if (required && value.length === 0) {
        invalidFields.push(name);
        continue;
      }

      if (required || value.length !== 0) {
        let invalid = false;

        // phone
        if (type === 'phone') {
          invalid = value.length < 14;
        }

        if (invalid) invalidFields.push(name);
      }
    }

    setInvalidFields(invalidFields);
    return invalidFields.length === 0;
  }

  /**
   * submit
   *
   * Validates and returns the form data
   * to the parent component.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object event
   * @return NA
   */
  const submit = event => {
    if (event) event.preventDefault();
    if (theForm.current.disabled) {
      console.log('disabled');
      return;
    }

    const formIsValid = validateFormData();
    if (formIsValid && onSubmit) {
      // disable the form
      theForm.current.disabled = true;

      // show loader
      if (!hideLoader) setFormLoaderIsOpen(true);

      // submit the form
      onSubmit(formData).then(
        ({ success, message, reset }) => {
          // enable the form
          theForm.current.disabled = false;

          // reset
          if (reset) updateFormData(prevFormData => {
            for (let key in prevFormData)
              prevFormData[key] = '';
            return prevFormData;
          });

          // hide loader
          if (!hideLoader) {
            if (!message) message = success
              ? 'Baixando apresentação...'
              : 'Ocorreu um erro ao enviar a sua mensagem.';
            setLoaderMessage(message);
            setTimeout(() => setFormLoaderIsOpen(false), 2000);
          }
        }
      );
    }
  }

  // make the submit method accessible
  // by the parent component
  useImperativeHandle(ref, () => ({ submit, formData }));

  // render
  return (
    <form
      ref={theForm}
      className={`form ${noStyle ? '' : 'styled'}`.trim()}
      onSubmit={submit}>
      {!hideLoader &&
      <div className={`form__loader ${formLoaderIsOpen ? 'open' : ''}`.trim()}>
        <div className="form__loader-message">
          {loaderMessage}
        </div>
      </div>
      }

      {children}
    </form>
  );
});

export default Form;
export { Field, SelectField };
