import React from 'react';
import './whatsapp.scss';

import { ReactComponent as WppIcon } from '../../assets/icons/whatsapp.svg';

const Whatsapp = () => {
  return (
    <div id="main-whatsapp">
      <a
        href="https://api.whatsapp.com/send?phone=553198741208"
        title="Fale por whatsapp"
        aria-label="Fale por whatsapp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <WppIcon />
      </a>
    </div>
  )
}

export default Whatsapp;