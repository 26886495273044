import React, { useState } from 'react';
import './Video.scss';

import Lightbox from 'fslightbox-react';

import BackgroundImage from '../../assets/img/video/background.png';
import BackgroundImageAVIF from '../../assets/img/video/background.avif';
import BackgroundImageWEBP from '../../assets/img/video/background.webp';
import TextImage from '../../assets/img/video/text-image.png';
import TextImageAVIF from '../../assets/img/video/text-image.avif';
import TextImageWEBP from '../../assets/img/video/text-image.webp';
import VideoThumbnailImage from '../../assets/img/video/video-thumbnail.jpg';
import VideoThumbnailImageAVIF from '../../assets/img/video/video-thumbnail.avif';
import VideoThumbnailImageWEBP from '../../assets/img/video/video-thumbnail.webp';

const Video = () => {
  /** @var boolean */
  const [videoIsOpen, setVideoOpenState] = useState(false);

  // render
  return (
    <section className="section-video">
      <Lightbox
        toggler={videoIsOpen}
        sources={['https://www.youtube.com/watch?v=nlDsX47-EHM']}
      />

      <div className="container">
        <div
          className="background"
          rel="lazy_load"
          data-url={BackgroundImage}
          data-webp={BackgroundImageWEBP}
          data-avif={BackgroundImageAVIF}
          data-distance="0.01"
        ></div>

        <div className="title">
          <div className="top">
            Aproveite a imensidão<br /> das águas para se
          </div>
          <picture>
            <source srcset={TextImageAVIF} type="image/avif" />  
            <source srcset={TextImageWEBP} type="image/webp" />  
            <img src={TextImage} alt="Aproveite a imensidão das águas para se conectar consigo mesmo" className="el-img"/>
          </picture>
          <div className="bottom">
            Consigo mesmo
          </div>
        </div>

        <div className="video-player">
          <button
            type="button"
            className="play-button"
            onClick={() => setVideoOpenState(!videoIsOpen)}>
          </button>

          <div
            className="thumbnail"
            rel="lazy_load"
            data-url={VideoThumbnailImage}
            data-webp={VideoThumbnailImageWEBP}
            data-avif={VideoThumbnailImageAVIF}
            data-distance="0.01"
          ></div>
        </div>
      </div>
    </section>
  );
}

export default Video;
