import axios from 'axios';

class Integrations {
  /**
   * sendToLeadPower
   *
   * Sends the specified data to LeadPower.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object data
   * @return promise
   */
  sendToLeadPower = async data => {
    const apiUrl = window.location.href.indexOf('localhost') !== -1
      ? '/leadpower'
      : 'https://painel.leadpower.com.br/api.php';

    try {
      const response = await axios.post(apiUrl, {
        company: 'Lotus Empreendimentos',
        project: 'LP - Mirrage',
        channel: 'CTA de Cadastro',
        ...data,
        email_list: [
          'marketing@lotusempreendimentos.com',
          'contato@lotusempreendimentos.com',
          'diretoria@lotusempreendimentos.com',
          'm.modenesi@lotusempreendimentos.com',
          'g.facilities@lotusempreendimentos.com',
          'vendas@lotusempreendimentos.com'
        ],
      });

      // reload the page
      const [ url ] = window.location.href.split('?');
      window.location.href = `${url}?obrigado=1`;

      return { success: true };
    } catch (err) {
      return { success: false };
    }
  }
}

export default new Integrations();
