import React from 'react';
import './Evolution.scss';
import { isMobile } from 'react-device-detect';

import ImageDesktop from '../../assets/img/evolution/evolution-image-desktop.png';
import ImageMobile from '../../assets/img/evolution/evolution-image-mobile.png';

const Evolution = () => {

  // render
  return (
    <section className="section-evolution">
      <div className="container">
        <div className="section-title">Evolução de obras</div>

        <div className="image">
          {isMobile ? 
            <img src={ImageMobile} alt="Imagem" />
            :
            <img src={ImageDesktop} alt="Imagem" />
          }
        </div>
      </div>
    </section>
  );
}

export default Evolution;
