import React, { useEffect } from 'react';
import './App.scss';

import { isMobile } from 'react-device-detect';

import LazyLoad from './lazy-load'

import MobileCta from './components/MobileCta/MobileCta';

import Whatsapp from './components/Whatsapp/';
import HeroSection from './sections/Hero/Hero';
import IntroSection from './sections/Intro/Intro';
import VideoSection from './sections/Video/Video';
import FeaturesSection from './sections/Features/Features';
import GallerySection from './sections/Gallery/Gallery';
import EvolutionSection from './sections/Evolution/Evolution';
import LocationSection from './sections/Location/Location';
import CallToActionSection from './sections/CallToAction/CallToAction';
import FooterSection from './sections/Footer/Footer';

import PresentationPdf from './assets/Mirrage-Nautic-Club.pdf';

const App = () => {

  // download the presentation
  useEffect(() => {
    if (
      window.location.href
        .indexOf('?obrigado=1') !== -1
    ) {
      const a = document.createElement('a');
      a.href = PresentationPdf;
      a.setAttribute('download', '');
      a.click();
    }
  }, []);

  // render
  return (
    <div className="App">
      <LazyLoad />
      
      {isMobile && <MobileCta />}
      <Whatsapp />
      <HeroSection />
      <IntroSection />
      <VideoSection />
      <FeaturesSection />
      <GallerySection />
      <EvolutionSection/>
      <LocationSection />
      <CallToActionSection />
      <FooterSection />
    </div>
  );
}

export default App;
